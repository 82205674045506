<script>
/***
 Name: example2
 Code: sa0ChunLuyu
 Time: 2021/10/15 23:58
 Remark: 示例2
 */
export default {
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div>
    <div v-for="i in 100" style="height: 100px;background: #cccccc;">{{ i }}</div>
  </div>
</template>
<style scoped>

</style>